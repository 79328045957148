<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <path
      d="m6.45,1c.31,0,.61.11.84.31l4.14,3.45c.3.25.47.62.47,1.01v6.7c0,.73-.59,1.32-1.32,1.32H2.32c-.73,0-1.32-.59-1.32-1.32v-6.7c0-.39.17-.76.47-1.01L5.61,1.31c.24-.2.54-.31.84-.31m0-1c-.53,0-1.05.18-1.48.54L.83,3.99c-.53.44-.83,1.09-.83,1.78v6.7c0,1.28,1.04,2.32,2.32,2.32h8.27c1.28,0,2.32-1.04,2.32-2.32v-6.7c0-.69-.31-1.34-.83-1.78L7.94.54c-.43-.36-.96-.54-1.48-.54h0Z"
      style="fill: #999; stroke-width: 0px"
    />
    <polyline
      points="4.72 14.33 4.72 8.85 8.19 8.85 8.19 14.33"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
